<template>
    <v-container class=" pt-0">

        <v-row class="align-center pt-0 " no-gutters>
            <v-col cols="12" v-if="error">
                <v-alert type="error" dismissible>
                    {{ error }}
                    <br>
                    {{ error.message }}
                    <br>
                    {{ error.schedule }}
                </v-alert>
            </v-col>
            <v-col cols="12" class="pa-0">
                <NextDaysCalendar v-model="date" :maxDays="$store.state.Auth.token.claims.type != 'superuser' ? 5 : 7"
                    @input="updateDate" :loading="loading" :unableInteraction="unableInteraction"
                    :disabledWeekDays="disabledWeekDays" />
            </v-col>
            <v-col cols="12">
                <PlanProgres @planCompleted="planCompleted" ref="plan-progress" :successQR="successQR" :date="date"
                    :qrCodeEncrypted="qrCodeEncrypted" @removeIncription="removeIncription" @showActions="showActions"
                    @hideActions="hideActions" @workedout="workedout" :inscription="inscript"></PlanProgres>
            </v-col>


            <v-col v-show="enabled && inscript == null && !loading">
                <v-subheader class="text-h6" v-if="classTypes.length < 2">
                    <v-icon left>mdi-clock-outline</v-icon>Horarios
                    <v-divider class="ml-4"> </v-divider>


                </v-subheader>

                <v-select v-else v-model="classType" filled class="" :items="classTypes" label="Ver horarios de"
                    hide-details="" dense @change="refilterSchedules"></v-select>
            </v-col>

            <v-col cols="12" v-if="loading">
                <v-skeleton-loader class="pt-4" style="width: 100%;height:140px" type="image"></v-skeleton-loader>
                <v-skeleton-loader class="pt-4" style="width: 100%;height:140px" type="image"></v-skeleton-loader>
                <v-skeleton-loader class="pt-4" style="width: 100%;height:140px" type="image"></v-skeleton-loader>
                <v-skeleton-loader class="pt-4" style="width: 100%;height:140px" type="image"></v-skeleton-loader>
            </v-col>

        </v-row>

        <v-fade-transition>

            <v-row v-show="(showScheduleClick && !workedToday) || isAfterDay">
                <template v-if="!inscript">


                    <v-col cols="12" md="6" v-for="elem in getSchedulesFiltered" :key="elem.id" class="py-1">
                        <v-card class="fill-height" elevation="0" :outlined="!$store.state.isDark">
                            <v-card-title><v-icon class="mr-2"></v-icon>{{
                                formatToHourMinutes(elem.startDate) }} hs<v-spacer></v-spacer>
                                <v-spacer></v-spacer>
                                <v-chip small disabled><v-icon v-show="elem.spots < 10" class="mr-1" color="orange">{{
                                    elem.spots == 0 ? 'mdi-information-off' : 'mdi-fire-alert' }} </v-icon> {{
                                            elem.spots }} lugares disponibles</v-chip>

                            </v-card-title>
                            <v-card-subtitle>{{ elem.timeRemaining }}</v-card-subtitle>
                            <v-card-actions>
                                <v-divider></v-divider>
                                <v-btn class="white--text" rounded color="success" @click="inscription(elem)"
                                    v-if="elem.spots > 0" :disabled="unableInteraction">
                                    <v-icon left>mdi-calendar-check-outline</v-icon>
                                    Reservar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>




                    <v-col cols="12" md="6" v-if="showNoMoreSchedules" class="pt-0">
                        <v-alert large color="primary darken-2" class=" text-h6 " v-if="!loading"><v-icon
                                left>mdi-information</v-icon>
                            No hay horarios disponibles, estamos cerrados.
                            <template v-if="extraMessage != ''">
                                <br /><br />
                                {{ extraMessage }}
                            </template>


                        </v-alert>
                    </v-col>
                </template>
            </v-row>
        </v-fade-transition>
    </v-container>
</template>

<script>
import moment from 'moment'
//import firebase from "./../firebase/index.js";
import { getFirestore, collection, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, query, where, serverTimestamp, Timestamp } from "firebase/firestore";
import PlanProgres from '@/components/PlanProgress.vue';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { getDatabase, ref, push, set, get, child, onChildChanged, off } from "firebase/database";
import Login from "@/models/login.js";
import NextDaysCalendar from "../components/NextDaysCalendar.vue";
import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)



export default {

    onbeforeunload() {
        // Check if the listener is set up and then remove it
        if (this.reservationListener) {
            this.reservationListener();
        }
    },
    beforeDestroy() {
        // Check if the listener is set up and then remove it
        if (this.reservationListener) {
            this.reservationListener();
        }
    },

    components: { PlanProgres, NextDaysCalendar },
    data() {
        return {
            classType: "Clase",
            classTypes: [],
            extraMessage: "",
            enabled: true,
            showScheduleClick: false,
            schedule: [],
            date: moment().format("YYYY-MM-DD"),
            minDate: moment().format('YYYY-MM-DD'),
            maxDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            menu: false,
            loading: false,
            unableInteraction: false,
            inscript: null,
            nextDisabled: false,
            showCrono: false,
            lastFocusTime: null,
            qrCodeEncrypted: null,
            idReservation: null,
            reservations: [],
            scheduleID: null,
            successQR: false,
            showNoMoreSchedules: false,
            workedToday: false,
            error: null,
            disabledWeekDays: [],
            scheduleToday: false,
            reservationListener: null,
        }
    },
    computed: {
        isAfterDay() {
            return moment(this.date, "YYYY-MM-DD").isAfter(moment().format("YYYY-MM-DD"));
        },
        getSchedulesFiltered() {
            let schedules = [...this.schedule];
            let selectedType = this.classType;


            return schedules.filter(schedule => {
                if (typeof schedule.type == "undefined") {
                    return true;
                }

                return schedule.type == selectedType;

            });
        }
    },
    async mounted() {

        this.$vuetify.goTo(0)
        //window.addEventListener("focus", this.handleFocus);
        //document.addEventListener("visibilitychange", this.handleVisibilityChange);
        //this.getReservations();

        try {
            let PlanProgress = this.$refs['plan-progress']

            //await this.getUser();
            //await this.getWorkoutsCompleted();

            this.loading = true;
            await this.getReservations();
            await PlanProgress.getUser();
            await PlanProgress.getWorkoutsCompleted();


            if (!this.scheduleToday && !this.workedToday) {
                this.inscript = null;
                this.qrCodeEncrypted = null;
                this.scheduleID = null;
                this.idReservation = null;
                this.successQR = false;
                await this.getDailySchedules();
            } else {
                this.enabled = false;
            }

            this.loading = false;

        } catch (error) {
            console.error(error);



        }


    },
    /*   beforeDestroy() {
          //window.removeEventListener("focus", this.handleFocus);
          //document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  
      }, */
    methods: {


        formatToHourMinutes(date) {
            
            if(date.seconds){
                return moment(date.seconds * 1000).format("HH:mm");
            }

            return moment(date).format("HH:mm");
        },

        async removeIncription() {
            await this.deinscription(this.inscript);

            this.showActions();

            if (this.schedule.length == 0) {
                await this.getDailySchedules();
            }

        },
        showActions() {
            //this.getReservations();
            this.enabled = true;
        },
        hideActions() {
            //this.getReservations();
            this.enabled = false;
        },
        workedout() {
            this.workedToday = true;
            this.enabled = false;
            this.schedule = [];
            this.showScheduleClick = false;
            //this.getReservations();

        },

        handleVisibilityChange() {
            if (!document.hidden) {
                const currentTime = new Date();
                if (this.lastFocusTime) {
                    const elapsedTime = (currentTime - this.lastFocusTime) / 1000;
                    if (elapsedTime > 600 && this.$store.state.Auth.token.claims.type != "superuser" && this.$store.state.Auth.token.claims.type != "admin") {
                        Login.logout()
                    }

                    if (elapsedTime > 60) {
                        this.refilterSchedules();
                    }
                }
                this.lastFocusTime = currentTime;
            }
        },
        handleFocus() {
            const currentTime = new Date();
            if (this.lastFocusTime) {
                const elapsedTime = (currentTime - this.lastFocusTime) / 1000;
                if (elapsedTime > 600 && this.$store.state.Auth.token.claims.type != "superuser" && this.$store.state.Auth.token.claims.type != "admin") {
                    Login.logout()
                }

                if (elapsedTime > 60) {
                    this.refilterSchedules();
                }
            }
            this.lastFocusTime = currentTime;
        },
        eventColor(schedule) {
            return schedule.spots > 0 ? "green" : "red";
        },
        async addReservation(reservation) {
            const db = getDatabase();
            const userid = this.$store.state.Auth.token.claims.user_id;

            let refer = ref(db, `${userid}/reservations`)

            let result = await push(refer, reservation);

            this.idReservation = result.key;

            this.qrCodeEncrypted = reservation.code
            this.scheduleID = reservation.schedule;

            this.reservations[result.key] = reservation;

            this.inscript = reservation
            this.onChildChangedReservationByID();

            return result.key;
        },
        async getReservations() {

            try {
                const db = getDatabase();
                const userid = this.$store.state.Auth.token.claims.user_id;
                let refer = ref(db, `${userid}/reservations`)
                let snapshot = await get(refer);
                let schduleToday = false;

                if (snapshot.exists()) {
                    let reservations = snapshot.val();
                    for (const key in reservations) {

                        if (Object.hasOwnProperty.call(reservations, key)) {
                            const reservation = reservations[key];
                            if (reservation.date == this.date + " " + reservation.id + ":00") {
                                this.qrCodeEncrypted = reservation.code
                                this.idReservation = key;
                                this.scheduleID = reservation.id;

                                this.scheduleToday = true;
                                if (!reservation.used) {
                                    this.inscript = reservation
                                    await this.onChildChangedReservationByID();


                                } else {
                                    this.workedToday = true;
                                }



                            }
                        }
                    }
                    this.reservations = reservations;

                } else {
                    this.reservations = [];
                }

            } catch (error) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error getting reservations:${error} ${error.message}`)
            }




        },
        async removeReservationByID() {
            let id = this.idReservation;
            const db = getDatabase();
            const userid = this.$store.state.Auth.token.claims.user_id;
            let refer = ref(db, `${userid}/reservations/${id}`)

            try {
                await set(refer, null)
            } catch (error) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error removing reservation ${id}:${error} ${error.message}`)
            }

            delete this.reservations[id];
        },
        onChildChangedReservationByID() {
            let id = this.idReservation;
            const db = getDatabase();
            const userid = this.$store.state.Auth.token.claims.user_id;

            let refer = ref(db, `${userid}/reservations/${id}`)

            this.reservationListener = onChildChanged(refer, (snapshot) => {
                let result = snapshot.val();
                if (result) {
                    this.successQR = true
                }
            });

        },
        async inscription(schedule) {

            let dataUserPlan = this.$refs['plan-progress'].returnUser().plan
            //let dataWorkoutsCompleted = this.$refs['plan-progress'].returnWorkoutsCompleted()

            if (this.reservations.length >= dataUserPlan) {
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Error al inscribirse",
                    text: "Ya alcanzaste el limite de reservas",
                });
                return;
            }

            this.unableInteraction = true
            // Get the current user's name
            const user = this.$store.state.Auth.token.claims.user_id
            let date = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD")

            const now = moment();
            const startDate = moment(schedule.startDate);
            // Calculate the difference in minutes
            const diffMinutes = startDate.diff(now, 'minutes');
            // Check if there's less than 5 minutes left
            if (diffMinutes < 5) {
                // Notify the user. You can use a modal, alert or any notification library you're using
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "warning",
                    title: "Error al inscribirse",
                    text: "Solo puedes inscribirte con hasta 5 minutos de anticipación",
                });
                this.unableInteraction = false
                return;  // Exit the function
            }

            // Get a reference to the schedule document
            const db = getFirestore()
            let scheduleRef = doc(db, `schedule/${date}/schedules/${schedule.id}`);
            let users;

            let spotsLeft;
            // Use a transaction to update the document
            let reservationOBJ;
            await runTransaction(db, async transaction => {
                const doc = await transaction.get(scheduleRef);
                if (!doc.exists) {
                    throw "Document does not exist!";
                }

                // Decrement the spots and add thge user to the users array
                const newData = doc.data();
                newData.spots--;
                spotsLeft = newData.spots
                let userObj = {
                    id: user
                }

                newData.users.push(userObj);
                users = newData.users
                // Update the document with the new data
                transaction.update(scheduleRef, newData);


                reservationOBJ = {
                    id: schedule.id,
                    userId: user,
                    date: date + " " + schedule.id + ":00",
                }

                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(reservationOBJ), 'YOUSHOULDSAYNOTOJACK~!@#$%^&*()_+');
                reservationOBJ = {
                    date: date + " " + schedule.id + ":00",
                    id: schedule.id,
                    userName: this.$store.state.Auth.token.claims.name || "",
                    used: false,
                    userId: user,
                    code: encryptedData.toString(),
                    type: schedule.id
                }


                let idOnRealtime = this.addReservation(reservationOBJ)



                this.qrCodeEncrypted = encryptedData.toString();
            });

            this.$vuetify.goTo(0)
            let scheduleInscript = this.schedule.findIndex(elem => elem.id == schedule.id)

            if (scheduleInscript != -1) {
                this.schedule[scheduleInscript].spots = spotsLeft;
                //this.schedule[scheduleInscript].users = users
            }



            this.inscript = reservationOBJ

            this.$forceUpdate()
            this.$notify({
                group: "feedback",
                duration: 5000,
                type: "success",
                title: "Reserva exitosa",
                text: "No te olvides cancelar si no podes asistir!",
            });

            this.unableInteraction = false
        },
        async deinscription(schedule) {
            const user = this.$store.state.Auth.token.claims.user_id;
            let date = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD")


            this.unableInteraction = true

            const db = getFirestore()
            let scheduleRef = doc(db, `schedule/${date}/schedules/${schedule.id}`);
            let users;
            let newData;
            await runTransaction(db, async transaction => {
                const doc = await transaction.get(scheduleRef);
                if (!doc.exists) {
                    throw "Document does not exist!";
                }

                newData = doc.data();
                newData.users = newData.users.filter(u => u.id != user);
                newData.spots = newData.spots + 1;
                users = newData.users
                transaction.update(scheduleRef, newData);

                let scheduleInscript = this.schedule.findIndex(elem => elem.id == schedule.id)
                if (scheduleInscript != -1) {
                    this.schedule[scheduleInscript].spots = newData.spots
                    this.schedule[scheduleInscript].users = users
                }
            }).catch((error) => {
                //console.error(error);
                this.error = error;
                this.error.schedule = schedule;
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Error al cancelar la reserva",
                    text: "Intenta nuevamente",
                });
            });


            try {
                await this.removeReservationByID();

                // Reset the reservation properties
                this.inscript = null;
                this.qrCodeEncrypted = null;
                this.scheduleID = null;
                this.unableInteraction = false;
                this.idReservation = null;
                this.successQR = false;
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "success",
                    title: "Eliminaste tu reserva",
                    text: "",
                });

            } catch (error) {
                console.error(error);
                this.error = error;
                this.error.schedule = schedule;
                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Error al cancelar la reserva",
                    text: "Intenta nuevamente",
                });
            }

        },
        async createDailySchedules() {
            const db = getFirestore()
            let date = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD")
            let scheduleIntervalsRef = doc(db, `configurations/schedule`);
            let scheduleIntervals = await getDoc(scheduleIntervalsRef);

            /// scheduleIntervalsData['Lunes'] = monday
            let scheduleIntervalsData = scheduleIntervals.data()

            let intervals;
            let day = moment(this.date, "YYYY-MM-DD").day()

            if (day == 0) {
                intervals = scheduleIntervalsData.Domingo
            }
            if (day == 1) {
                intervals = scheduleIntervalsData.Lunes
            }
            if (day == 2) {
                intervals = scheduleIntervalsData.Martes
            }
            if (day == 3) {
                intervals = scheduleIntervalsData.Miercoles
            }
            if (day == 4) {
                intervals = scheduleIntervalsData.Jueves
            }
            if (day == 5) {
                intervals = scheduleIntervalsData.Viernes
            }
            if (day == 6) {
                intervals = scheduleIntervalsData.Sabado
            }


            // now it should check the specialDays property in the scheduleIntervalsData object, its an array with objects with date, and intervals in the range property.
            let specialDays = scheduleIntervalsData.specialDays || [];
            for (const specialDay of specialDays) {
                if (specialDay.date == date) {
                    intervals = specialDay.ranges
                    this.extraMessage = specialDay.title
                }
            }

            if (intervals.length == 0) {
                return false
            }


            const dateScheduleRef = doc(db, `schedule`, `${date}`);
            await setDoc(dateScheduleRef, {});

            for (const interval of intervals) {
                let interval_start_datetime = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD") + " " + interval.start + ":00"
                let interval_end_datetime = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD") + " " + interval.end + ":00"

                const startTime = new Date(interval_start_datetime);
                const endTime = new Date(interval_end_datetime);

                let currentTime = startTime;
                while (currentTime < endTime) {
                    const schedule = {
                        startDate: currentTime,
                        duration: parseInt(interval.duration),
                        spots: 30,
                        users: [],
                        color: interval.color,
                        type: interval.type
                    };

                    const scheduleRef = await doc(db, `schedule/${date}/schedules`, moment(currentTime).format("HH:mm") + interval.type);
                    await setDoc(scheduleRef, schedule);

                    // Increment the current time by the interval duration
                    currentTime = new Date(currentTime.getTime() + (parseInt(interval.duration) * 60000));
                }
            }
        }
        ,
        async getDailySchedules() {

            try{

            this.loading = true;
            this.schedule = [];
            this.inscript = null;
            this.classTypes = [];
            const db = getFirestore()
            let user = this.$store.state.Auth.token.claims.user_id;

            let date = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD")

            let dateCheck = moment(this.date, 'YYYY-MM-DD').format("YYYY-MM-DD");

            if (this.maxDate == dateCheck) {
                this.nextDisabled = true;
            }

            const dateScheduleRef = doc(db, `schedule/${date}`);
            const dateSchedule = await getDoc(dateScheduleRef);

            if (!dateSchedule.exists()) {
                await this.createDailySchedules()
                logAuditEvent('create', this.$store.state.Auth.token.claims.user_id, `Created daily schedules for ${date}`)
            }

            // Use query with where() to fetch only the documents that have not started
            let now = new Date();
            let q = query(
                collection(db, `schedule/${date}/schedules`),
                where("startDate", ">", moment().toDate())
            );
            let querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                q = query(
                    collection(db, `schedule/${date}/schedules`),
                    where("startDate", ">", moment().format("YYYY-MM-DD HH:mm:ss"))
                );

                querySnapshot = await getDocs(q);
            }
            
            let schedule = [];


            querySnapshot.forEach(doc => {
                let elem = doc.data()

                if (typeof elem.type == "undefined") {
                    elem.type = "Clase"
                }

                if (!this.classTypes.includes(elem.type)) {
                    this.classTypes.push(elem.type)
                }


                delete elem['users']
                elem.id = doc.id
                let startDate;
                if(elem.startDate.seconds){
                    startDate = new Date(elem.startDate.seconds * 1000);
                }else{
                    startDate = new Date(elem.startDate);
                }

            
                // Calculate the end date by adding the duration in minutes
                const endDate = new Date(startDate.getTime() + (elem.duration * 60000));

                if (now < startDate) {
                    schedule.push(elem);
                    //console.log(`Schedule has not started yet`);
                } else if (now >= startDate && now <= endDate) {
                    //console.log(`Schedule is happening now`);
                } else {
                    /// console.log(`Schedule has already finished`);
                }

                /* elem.users.forEach(userObj => {
                    if (user == userObj.name) {
                        this.inscript = elem 
                    }

                }) */
            });
            this.schedule = schedule.sort(function (a, b) {
                return a.id - b.id;
            });

            if (this.schedule.length == 0) {
                this.showNoMoreSchedules = true;
            } else {
                this.showNoMoreSchedules = false;
            }

            this.loading = false;
            this.showScheduleClick = true;
            logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Read daily schedules for ${date} ${moment().format("YYYY-MM-DD HH:mm:ss")}  ${this.schedule.length} schedules`)

            }catch(error){
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error getting daily schedules:${error} ${error.message}`)
            }finally{
                this.loading = false;
            }
        },

        refilterSchedules() {
            let schedules = this.schedule;
            const now = new Date();

            schedules.forEach((doc, index) => {
                let elem = doc
                elem.id = doc.id
                elem.started = false
                elem.finished = false
                elem.display = elem.type == this.classType ? true : false
                const startDate = new Date(elem.startDate.seconds * 1000);
                // Calculate the end date by adding the duration in minutes
                const endDate = new Date(startDate.getTime() + (elem.duration * 60000));

                if (now < startDate) {
                    ///console.log(`Schedule has not started yet`);
                } else if (now >= startDate && now <= endDate) {
                    //console.log(`Schedule is happening now`);
                    this.schedule[index].started = true;
                } else {
                    this.schedule[index].finished = true;
                    //console.log(`Schedule has already finished`);
                }
            });
        },
        async updateCalendar(bool) {
            if (bool) {
                this.date = moment(this.date, "YYYY-MM-DD")
                    .add(1, "days")
                    .format("YYYY-MM-DD");
            } else {
                this.date = moment(this.date, "YYYY-MM-DD")
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");
            }
            await this.getDailySchedules();

        },
        timeRemaining(date) {
            let now = new Date();
            let duration = moment.duration(moment(date).diff(moment(now)));
            let days = duration.asDays();
            let hours = duration.asHours();
            let minutes = duration.asMinutes();
            let seconds = duration.asSeconds();
            if (days >= 1) {
                return moment.utc(duration.asMilliseconds()).format("D [days]");
            } else if (hours >= 1) {
                return moment.utc(duration.asMilliseconds()).format("H [hours]");
            } else if (minutes >= 1) {
                return moment.utc(duration.asMilliseconds()).format("m [minutes]");
            } else {
                return moment.utc(duration.asMilliseconds()).format("s [seconds]");
            }
        },
        async dateChange() {
            this.extraMessage = "";
            this.enabled = true;
            this.$refs['plan-progress'].setHideFinish(this.date, false);
            let boolCheck = false
            for (const key in this.reservations) {

                if (Object.hasOwnProperty.call(this.reservations, key)) {
                    const reservation = this.reservations[key];
                    if (reservation.date == this.date + " " + reservation.id + ":00") {
                        this.qrCodeEncrypted = reservation.code
                        this.idReservation = key;
                        this.scheduleID = reservation.id;
                        this.inscript = reservation
                        boolCheck = true

                        if (reservation.used) {

                        } else {
                            this.successQR = false;
                        }
                    }
                }
            }

            if (moment(this.date).isAfter(moment(), 'day')) {
                this.successQR = false;
            }

            if (boolCheck) {
                this.hideActions();
                this.schedule = []
                this.showScheduleClick = false;



            } else {
                if (this.workedToday && moment().isSame(moment(this.date), 'day')) {
                    this.hideActions();
                    this.schedule = [];
                    this.showScheduleClick = false;

                } else {
                    this.showScheduleClick = true;
                    await this.getDailySchedules();

                }
            }


        },
        updateDate(newDate) {

            this.date = newDate;
            this.dateChange();


        },
        planCompleted() {
            // Find the next Monday
            let nextMonday = moment().startOf('week').add(1, 'weeks').day(1);

            // Make sure disabledWeekDays is initialized as an array
            if (!Array.isArray(this.disabledWeekDays)) {
                this.disabledWeekDays = [];
            }

            // Add dates from next Monday to the Monday after
            for (let index = 0; index < 8; index++) {
                let dateToAdd = nextMonday.clone().add(index, "days").format("YYYY-MM-DD");
                this.disabledWeekDays.push(dateToAdd);
            }

        }

    },
}
</script>
