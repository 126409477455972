<template>
    <v-row class="mt-5">
            <div style="    display: block;
    width: 100%;
    position: relative;">
            <template v-if="successQR">
                <v-icon  style="font-size: 100px;
    position: absolute;
    left: 35%;
    top: 24%;
    background: whitesmoke;
    border-radius: 100px;" color="success" size="100">mdi-check</v-icon>
            </template>
            <img v-if="qrCodeImageUrl" :src="qrCodeImageUrl" width="220px" height="220px" style="display:block;margin:auto;border-radius: 14px;">
        </div>
            <vue-qrious v-if="qrCodeEncrypted" :value="qrCodeEncrypted" @change="onDataUrlChange" />
            <p v-if="!successQR" class="text-center mt-2"  style="
                width: 100%;
            ">Tu QR para el acceso.</p>
            <p v-else class="text-center mt-2"  style="
                width: 100%;
            ">Check in exitoso!</p>
    </v-row>
  </template>
  <script>
  import VueQrious from 'vue-qrious';
  
  export default {
    props: {
        qrCodeEncrypted : String,
        successQR: Boolean
    }, 
    components: {
      VueQrious
    },
    data() {
        return{
            qrCodeImageUrl:''
        }
       
    },
    mounted() {
    },
    methods: {
      onDataUrlChange(dataUrl) {
        this.qrCodeImageUrl = dataUrl;
      },
    },
    computed: {
      encryptedQrCode() {
        return this.qrCodeEncrypted;
      },
    },
  };
  </script>
  