<template>
  <v-row >
    <v-col v-for="(date, i) in dates" :key="i" :cols="12 / dates.length" class="px-0">
    <v-badge bottom
    dot
      color="primary" :value="date === today" style="width: 100%;">
        <v-card :disabled="loading || unableInteraction || disabledWeekDays.includes(date)" class="pa-3 text-center rounded-0" elevation="0" width="100%" :color="value == date ? $store.state.isDark? 'grey darken-3' : 'grey lighten-4': ''"
          @click="updateValue(date)">
          <span class="text-subtitle-2 font-weight-light">{{
            formatDate(date, "ddd")
          }}</span>
          <br />
          <span class="text-h5 font-weight-bold">{{
            formatDate(date, "DD")
          }}</span>
        </v-card>
      </v-badge>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "NextDaysCalendar",

  props: {
    value: String,
    maxDays: {
      type: Number,
      default: 3,
    },
    loading: Boolean,
    unableInteraction: Boolean,
    disabledWeekDays: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dates: null,
      today: moment().format("YYYY-MM-DD"),
    };
  },

  created() {
    let dates = [moment().format("YYYY-MM-DD")];
    for (let i = 1; i < this.maxDays; i++) {
      dates.push(moment().add(i, "days").format("YYYY-MM-DD"));
    }
    this.dates = dates;
  },

  methods: {
    formatDate(date, format) {
      let now = moment();
      if (moment(date).isSame(now, 'day') && format==='ddd') {
        return "Hoy";
      }
      return moment(date).locale("es").format(format) ;
    },
    updateValue(date) {
      if (this.$props.loading) return;
      if (this.$props.value === date) return;
      this.$emit('input', date);
    },
  },
};
</script>

<style >
.v-badge--dot .v-badge__badge {
  inset: calc(95% - 2px) auto auto calc(50% - 2px) !important;

}
</style>
